import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './external-referral.reducer';

export const ExternalReferralDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const externalReferralEntity = useAppSelector(state => state.externalReferral.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="externalReferralDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.externalReferral.detail.title">ExternalReferral</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.externalReferral.id">Id</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.id}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.externalReferral.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.timestampEntry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.externalReferral.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.microSec}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.externalReferral.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.lastUpdated}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.externalReferral.active">Active</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.externalReferral.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.cursor}</dd>
          <dt>
            <span id="referredById">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredById">Referred By Id</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredById}</dd>
          <dt>
            <span id="referredByRole">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredByRole">Referred By Role</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredByRole}</dd>
          <dt>
            <span id="referredDate">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredDate">Referred Date</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredDate}</dd>
          <dt>
            <span id="referralNotes">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referralNotes">Referral Notes</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referralNotes}</dd>
          <dt>
            <span id="referringTenantId">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referringTenantId">Referring Tenant Id</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referringTenantId}</dd>
          <dt>
            <span id="referringTenantType">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referringTenantType">Referring Tenant Type</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referringTenantType}</dd>
          <dt>
            <span id="referredEntity">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntity">Referred Entity</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntity}</dd>
          <dt>
            <span id="referredEntityType">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityType">Referred Entity Type</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityType}</dd>
          <dt>
            <span id="referredEntityAddrLine1">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityAddrLine1">Referred Entity Addr Line 1</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityAddrLine1}</dd>
          <dt>
            <span id="referredEntityAddrLine2">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityAddrLine2">Referred Entity Addr Line 2</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityAddrLine2}</dd>
          <dt>
            <span id="referredEntityCity">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityCity">Referred Entity City</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityCity}</dd>
          <dt>
            <span id="referredEntityState">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityState">Referred Entity State</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityState}</dd>
          <dt>
            <span id="referredEntityPincode">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredEntityPincode">Referred Entity Pincode</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredEntityPincode}</dd>
          <dt>
            <span id="referredToUser">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredToUser">Referred To User</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredToUser}</dd>
          <dt>
            <span id="referredToRole">
              <Translate contentKey="tinyMedicRestApp.externalReferral.referredToRole">Referred To Role</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.referredToRole}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="tinyMedicRestApp.externalReferral.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{externalReferralEntity.createdBy}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.externalReferral.patient">Patient</Translate>
          </dt>
          <dd>{externalReferralEntity.patient ? externalReferralEntity.patient.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/external-referral" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/external-referral/${externalReferralEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ExternalReferralDetail;
