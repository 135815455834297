import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApprovedRefund from './approved-refund';
import ApprovedRefundDetail from './approved-refund-detail';
import ApprovedRefundUpdate from './approved-refund-update';
import ApprovedRefundDeleteDialog from './approved-refund-delete-dialog';

const ApprovedRefundRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApprovedRefund />} />
    <Route path="new" element={<ApprovedRefundUpdate />} />
    <Route path=":id">
      <Route index element={<ApprovedRefundDetail />} />
      <Route path="edit" element={<ApprovedRefundUpdate />} />
      <Route path="delete" element={<ApprovedRefundDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApprovedRefundRoutes;
