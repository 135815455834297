import { IGlucoUser } from 'app/shared/model/gluco-user.model';

export interface IExternalReferral {
  id?: string;
  timestampEntry?: number;
  microSec?: number;
  lastUpdated?: number;
  active?: boolean;
  cursor?: number;
  referredById?: string;
  referredByRole?: string;
  referredDate?: number;
  referralNotes?: string | null;
  referringTenantId?: string;
  referringTenantType?: string;
  referredEntity?: string | null;
  referredEntityType?: string | null;
  referredEntityAddrLine1?: string | null;
  referredEntityAddrLine2?: string | null;
  referredEntityCity?: string | null;
  referredEntityState?: string | null;
  referredEntityPincode?: string | null;
  referredToUser?: string | null;
  referredToRole?: string | null;
  createdBy?: string | null;
  patient?: IGlucoUser | null;
}

export const defaultValue: Readonly<IExternalReferral> = {
  active: false,
};
