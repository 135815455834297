import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './approved-refund.reducer';

export const ApprovedRefundDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const approvedRefundEntity = useAppSelector(state => state.approvedRefund.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="approvedRefundDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.approvedRefund.detail.title">ApprovedRefund</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.id">Id</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.id}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.createdBy}</dd>
          <dt>
            <span id="createdByType">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.createdByType">Created By Type</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.createdByType}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.timestampEntry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.microSec}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.lastUpdated}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.active">Active</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.cursor}</dd>
          <dt>
            <span id="discountValue">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.discountValue">Discount Value</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.discountValue}</dd>
          <dt>
            <span id="approvedBy">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.approvedBy">Approved By</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.approvedBy}</dd>
          <dt>
            <span id="approvedRole">
              <Translate contentKey="tinyMedicRestApp.approvedRefund.approvedRole">Approved Role</Translate>
            </span>
          </dt>
          <dd>{approvedRefundEntity.approvedRole}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.approvedRefund.pharmacyBill">Pharmacy Bill</Translate>
          </dt>
          <dd>{approvedRefundEntity.pharmacyBill ? approvedRefundEntity.pharmacyBill.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.approvedRefund.hospitalBill">Hospital Bill</Translate>
          </dt>
          <dd>{approvedRefundEntity.hospitalBill ? approvedRefundEntity.hospitalBill.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.approvedRefund.diaglabBill">Diaglab Bill</Translate>
          </dt>
          <dd>{approvedRefundEntity.diaglabBill ? approvedRefundEntity.diaglabBill.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/approved-refund" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/approved-refund/${approvedRefundEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApprovedRefundDetail;
