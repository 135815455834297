import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPharmacyBills } from 'app/entities/pharmacy-bill/pharmacy-bill.reducer';
import { getEntities as getHospitalBills } from 'app/entities/hospital-bill/hospital-bill.reducer';
import { getEntities as getDiaglabBills } from 'app/entities/diaglab-bill/diaglab-bill.reducer';
import { createEntity, getEntity, reset, updateEntity } from './approved-refund.reducer';

export const ApprovedRefundUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const pharmacyBills = useAppSelector(state => state.pharmacyBill.entities);
  const hospitalBills = useAppSelector(state => state.hospitalBill.entities);
  const diaglabBills = useAppSelector(state => state.diaglabBill.entities);
  const approvedRefundEntity = useAppSelector(state => state.approvedRefund.entity);
  const loading = useAppSelector(state => state.approvedRefund.loading);
  const updating = useAppSelector(state => state.approvedRefund.updating);
  const updateSuccess = useAppSelector(state => state.approvedRefund.updateSuccess);

  const handleClose = () => {
    navigate(`/approved-refund${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPharmacyBills({}));
    dispatch(getHospitalBills({}));
    dispatch(getDiaglabBills({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.discountValue !== undefined && typeof values.discountValue !== 'number') {
      values.discountValue = Number(values.discountValue);
    }

    const entity = {
      ...approvedRefundEntity,
      ...values,
      pharmacyBill: pharmacyBills.find(it => it.id.toString() === values.pharmacyBill?.toString()),
      hospitalBill: hospitalBills.find(it => it.id.toString() === values.hospitalBill?.toString()),
      diaglabBill: diaglabBills.find(it => it.id.toString() === values.diaglabBill?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...approvedRefundEntity,
          pharmacyBill: approvedRefundEntity?.pharmacyBill?.id,
          hospitalBill: approvedRefundEntity?.hospitalBill?.id,
          diaglabBill: approvedRefundEntity?.diaglabBill?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.approvedRefund.home.createOrEditLabel" data-cy="ApprovedRefundCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.approvedRefund.home.createOrEditLabel">Create or edit a ApprovedRefund</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="approved-refund-id"
                  label={translate('tinyMedicRestApp.approvedRefund.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.createdBy')}
                id="approved-refund-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.createdByType')}
                id="approved-refund-createdByType"
                name="createdByType"
                data-cy="createdByType"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.timestampEntry')}
                id="approved-refund-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.microSec')}
                id="approved-refund-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.lastUpdated')}
                id="approved-refund-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.active')}
                id="approved-refund-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.cursor')}
                id="approved-refund-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.discountValue')}
                id="approved-refund-discountValue"
                name="discountValue"
                data-cy="discountValue"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.approvedBy')}
                id="approved-refund-approvedBy"
                name="approvedBy"
                data-cy="approvedBy"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.approvedRefund.approvedRole')}
                id="approved-refund-approvedRole"
                name="approvedRole"
                data-cy="approvedRole"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="approved-refund-pharmacyBill"
                name="pharmacyBill"
                data-cy="pharmacyBill"
                label={translate('tinyMedicRestApp.approvedRefund.pharmacyBill')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacyBills
                  ? pharmacyBills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="approved-refund-hospitalBill"
                name="hospitalBill"
                data-cy="hospitalBill"
                label={translate('tinyMedicRestApp.approvedRefund.hospitalBill')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalBills
                  ? hospitalBills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="approved-refund-diaglabBill"
                name="diaglabBill"
                data-cy="diaglabBill"
                label={translate('tinyMedicRestApp.approvedRefund.diaglabBill')}
                type="select"
              >
                <option value="" key="0" />
                {diaglabBills
                  ? diaglabBills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/approved-refund" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApprovedRefundUpdate;
