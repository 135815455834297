import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { createEntity, getEntity, reset, updateEntity } from './external-referral.reducer';

export const ExternalReferralUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const externalReferralEntity = useAppSelector(state => state.externalReferral.entity);
  const loading = useAppSelector(state => state.externalReferral.loading);
  const updating = useAppSelector(state => state.externalReferral.updating);
  const updateSuccess = useAppSelector(state => state.externalReferral.updateSuccess);

  const handleClose = () => {
    navigate(`/external-referral${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getGlucoUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.referredDate !== undefined && typeof values.referredDate !== 'number') {
      values.referredDate = Number(values.referredDate);
    }

    const entity = {
      ...externalReferralEntity,
      ...values,
      patient: glucoUsers.find(it => it.id.toString() === values.patient?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...externalReferralEntity,
          patient: externalReferralEntity?.patient?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.externalReferral.home.createOrEditLabel" data-cy="ExternalReferralCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.externalReferral.home.createOrEditLabel">Create or edit a ExternalReferral</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="external-referral-id"
                  label={translate('tinyMedicRestApp.externalReferral.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.timestampEntry')}
                id="external-referral-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.microSec')}
                id="external-referral-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.lastUpdated')}
                id="external-referral-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.active')}
                id="external-referral-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.cursor')}
                id="external-referral-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredById')}
                id="external-referral-referredById"
                name="referredById"
                data-cy="referredById"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredByRole')}
                id="external-referral-referredByRole"
                name="referredByRole"
                data-cy="referredByRole"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredDate')}
                id="external-referral-referredDate"
                name="referredDate"
                data-cy="referredDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referralNotes')}
                id="external-referral-referralNotes"
                name="referralNotes"
                data-cy="referralNotes"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referringTenantId')}
                id="external-referral-referringTenantId"
                name="referringTenantId"
                data-cy="referringTenantId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referringTenantType')}
                id="external-referral-referringTenantType"
                name="referringTenantType"
                data-cy="referringTenantType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntity')}
                id="external-referral-referredEntity"
                name="referredEntity"
                data-cy="referredEntity"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityType')}
                id="external-referral-referredEntityType"
                name="referredEntityType"
                data-cy="referredEntityType"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityAddrLine1')}
                id="external-referral-referredEntityAddrLine1"
                name="referredEntityAddrLine1"
                data-cy="referredEntityAddrLine1"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityAddrLine2')}
                id="external-referral-referredEntityAddrLine2"
                name="referredEntityAddrLine2"
                data-cy="referredEntityAddrLine2"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityCity')}
                id="external-referral-referredEntityCity"
                name="referredEntityCity"
                data-cy="referredEntityCity"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityState')}
                id="external-referral-referredEntityState"
                name="referredEntityState"
                data-cy="referredEntityState"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredEntityPincode')}
                id="external-referral-referredEntityPincode"
                name="referredEntityPincode"
                data-cy="referredEntityPincode"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredToUser')}
                id="external-referral-referredToUser"
                name="referredToUser"
                data-cy="referredToUser"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.referredToRole')}
                id="external-referral-referredToRole"
                name="referredToRole"
                data-cy="referredToRole"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.externalReferral.createdBy')}
                id="external-referral-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                id="external-referral-patient"
                name="patient"
                data-cy="patient"
                label={translate('tinyMedicRestApp.externalReferral.patient')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/external-referral" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ExternalReferralUpdate;
