import { IPharmacyBill } from 'app/shared/model/pharmacy-bill.model';
import { IHospitalBill } from 'app/shared/model/hospital-bill.model';
import { IDiaglabBill } from 'app/shared/model/diaglab-bill.model';

export interface IApprovedRefund {
  id?: string;
  createdBy?: string | null;
  createdByType?: string | null;
  timestampEntry?: number;
  microSec?: number;
  lastUpdated?: number;
  active?: boolean;
  cursor?: number;
  discountValue?: number;
  approvedBy?: string;
  approvedRole?: string;
  pharmacyBill?: IPharmacyBill | null;
  hospitalBill?: IHospitalBill | null;
  diaglabBill?: IDiaglabBill | null;
}

export const defaultValue: Readonly<IApprovedRefund> = {
  active: false,
};
