import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ExternalReferral from './external-referral';
import ExternalReferralDetail from './external-referral-detail';
import ExternalReferralUpdate from './external-referral-update';
import ExternalReferralDeleteDialog from './external-referral-delete-dialog';

const ExternalReferralRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ExternalReferral />} />
    <Route path="new" element={<ExternalReferralUpdate />} />
    <Route path=":id">
      <Route index element={<ExternalReferralDetail />} />
      <Route path="edit" element={<ExternalReferralUpdate />} />
      <Route path="delete" element={<ExternalReferralDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ExternalReferralRoutes;
